import store from "@/store";

// VUE_APP_RESERVATION_PATH_STEP1 = "step1";
// VUE_APP_RESERVATION_PATH_STEP2 = "step2";
// VUE_APP_RESERVATION_PATH_STEP3 = "step3";
// VUE_APP_RESERVATION_PATH_STEP4 = "step4";
// VUE_APP_RESERVATION_PATH_FINAL = "success";

const getStep = (key) => {
  switch (key) {
    case "1":
    case 1:
      return process.env.VUE_APP_RESERVATION_PATH_STEP1;
    case "2":
    case 2:
      return process.env.VUE_APP_RESERVATION_PATH_STEP2;
    case "3":
    case 3:
      return process.env.VUE_APP_RESERVATION_PATH_STEP3;
    case "4":
    case 4:
      return process.env.VUE_APP_RESERVATION_PATH_STEP4;
    case "success":
      return process.env.VUE_APP_RESERVATION_PATH_FINAL;
    default:
      break;
  }
};

export async function routeToSteps(app, step, params) {
  // console.log("Routing", app, step, getStep(step), params);
  // console.log(process.env.VUE_APP_STANDALONE);
  const branch = await store.getters["userSession/GET_BRANCH"];
  if (process.env.VUE_APP_STANDALONE == "true" || params) {
    // this.$router.push({
    //   name: "Reservation_Final",
    //   params: { data: result.data },
    // });
    if (branch === "Auto-Abo") {
      app.$router.push({
        name: "Abo_Step" + step,
        params: { locale: app.$route.params.locale, params },
      });
    } else {
      app.$router.push({
        name: "Reservation_Step" + step,
        params: { locale: app.$route.params.locale, params },
      });
    }
  } else {
    if (app.loading) {
      app.loading = false;
    }
    const locale = app.$route.params.locale
      ? "/" + app.$route.params.locale
      : "";
    const baseUrl = window.location.origin + locale;
    if (branch === "Auto-Abo") {
      window.location.href =
        baseUrl +
        process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_ABO_PATH +
        getStep(step);
    } else {
      window.location.href =
        baseUrl +
        process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_RESERVATION_PATH +
        getStep(step);
    }
  }
}

// export async function routeToStep1(app) {
//   const branch = await store.getters["userSession/GET_BRANCH"];
//   if (process.env.VUE_APP_STANDALONE == "true") {
//     if (branch === "Auto-Abo") {
//       app.$router.push({ name: "Abo_Step1" });
//     } else {
//       app.$router.push({ name: "Reservation_Step1" });
//     }
//   } else {
//     const baseUrl = window.location.origin;
//     if (branch === "Auto-Abo") {
//       window.location.href =
//         baseUrl +
//         process.env.VUE_APP_BASE_URL +
//         process.env.VUE_APP_ABO_PATH +
//         process.env.VUE_APP_RESERVATION_PATH_STEP1;
//     } else {
//       window.location.href =
//         baseUrl +
//         process.env.VUE_APP_BASE_URL +
//         process.env.VUE_APP_RESERVATION_PATH +
//         process.env.VUE_APP_RESERVATION_PATH_STEP1;
//     }
//   }
// }

// export async function routeToStep2(app) {
//   const branch = await store.getters["userSession/GET_BRANCH"];

//   if (process.env.VUE_APP_STANDALONE == "true") {
//     if (branch === "Auto-Abo") {
//       app.$router.push({ name: "Abo_Step2" });
//     } else {
//       app.$router.push({ name: "Reservation_Step2" });
//     }
//   } else {
//     const baseUrl = window.location.origin;
//     if (branch === "Auto-Abo") {
//       window.location.href =
//         baseUrl +
//         process.env.VUE_APP_BASE_URL +
//         process.env.VUE_APP_ABO_PATH +
//         process.env.VUE_APP_RESERVATION_PATH_STEP2;
//     } else {
//       window.location.href =
//         baseUrl +
//         process.env.VUE_APP_BASE_URL +
//         process.env.VUE_APP_RESERVATION_PATH +
//         process.env.VUE_APP_RESERVATION_PATH_STEP2;
//     }
//   }
// }

// export async function routeToStep3(app) {
//   const branch = await store.getters["userSession/GET_BRANCH"];

//   if (process.env.VUE_APP_STANDALONE == "true") {
//     if (branch === "Auto-Abo") {
//       app.$router.push({ name: "Abo_Step3" });
//     } else {
//       app.$router.push({ name: "Reservation_Step3" });
//     }
//   } else {
//     const baseUrl = window.location.origin;
//     if (branch === "Auto-Abo") {
//       window.location.href =
//         baseUrl +
//         process.env.VUE_APP_BASE_URL +
//         process.env.VUE_APP_ABO_PATH +
//         process.env.VUE_APP_RESERVATION_PATH_STEP3;
//     } else {
//       window.location.href =
//         baseUrl +
//         process.env.VUE_APP_BASE_URL +
//         process.env.VUE_APP_RESERVATION_PATH +
//         process.env.VUE_APP_RESERVATION_PATH_STEP3;
//     }
//   }
// }

// export async function routeToStep4(app) {
//   const branch = await store.getters["userSession/GET_BRANCH"];

//   if (process.env.VUE_APP_STANDALONE == "true") {
//     if (branch === "Auto-Abo") {
//       app.$router.push({ name: "Abo_Step4" });
//     } else {
//       app.$router.push({ name: "Reservation_Step4" });
//     }
//   } else {
//     const baseUrl = window.location.origin;
//     if (branch === "Auto-Abo") {
//       window.location.href =
//         baseUrl +
//         process.env.VUE_APP_BASE_URL +
//         process.env.VUE_APP_ABO_PATH +
//         process.env.VUE_APP_RESERVATION_PATH_STEP4;
//     } else {
//       window.location.href =
//         baseUrl +
//         process.env.VUE_APP_BASE_URL +
//         process.env.VUE_APP_RESERVATION_PATH +
//         process.env.VUE_APP_RESERVATION_PATH_STEP4;
//     }
//   }
// }

// export async function routeToSuccess(app) {
//   const branch = await store.getters["userSession/GET_BRANCH"];

//   if (process.env.VUE_APP_STANDALONE == "true") {
//     if (branch === "Auto-Abo") {
//       app.$router.push({ name: "Abo_Final" });
//     } else {
//       app.$router.push({ name: "Reservation_Final" });
//     }
//   } else {
//     const baseUrl = window.location.origin;
//     if (branch === "Auto-Abo") {
//       window.location.href =
//         baseUrl +
//         process.env.VUE_APP_BASE_URL +
//         process.env.VUE_APP_ABO_PATH +
//         process.env.VUE_APP_RESERVATION_PATH_FINAL;
//     } else {
//       window.location.href =
//         baseUrl +
//         process.env.VUE_APP_BASE_URL +
//         process.env.VUE_APP_RESERVATION_PATH +
//         process.env.VUE_APP_RESERVATION_PATH_FINAL;
//     }
//   }
// }
export async function getPayRoutes() {
  const branch = await store.getters["userSession/GET_BRANCH"];
  const hostname =
    window.location.hostname == "localhost"
      ? "localhost:8080"
      : window.location.hostname;
  if (branch === "Auto-Abo") {
    return {
      successUrl:
        window.location.protocol +
        "//" +
        hostname +
        process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_ABO_PATH +
        process.env.VUE_APP_RESERVATION_PATH_STEP4 +
        "?SUCCCESS",
      failureUrl:
        window.location.protocol +
        "//" +
        hostname +
        process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_ABO_PATH +
        process.env.VUE_APP_RESERVATION_PATH_STEP4 +
        "?FAILURE",
      cancelUrl:
        window.location.protocol +
        "//" +
        hostname +
        process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_ABO_PATH +
        process.env.VUE_APP_RESERVATION_PATH_STEP4 +
        "?CANCEL",
      confirmUrl:
        window.location.protocol +
        "//" +
        hostname +
        process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_ABO_PATH +
        process.env.VUE_APP_RESERVATION_PATH_STEP4 +
        "?CONFIRM",
    };
  } else {
    return {
      successUrl:
        window.location.protocol +
        "//" +
        hostname +
        process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_RESERVATION_PATH +
        process.env.VUE_APP_RESERVATION_PATH_STEP4 +
        "?SUCCCESS",
      failureUrl:
        window.location.protocol +
        "//" +
        hostname +
        process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_RESERVATION_PATH +
        process.env.VUE_APP_RESERVATION_PATH_STEP4 +
        "?FAILURE",
      cancelUrl:
        window.location.protocol +
        "//" +
        hostname +
        process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_RESERVATION_PATH +
        process.env.VUE_APP_RESERVATION_PATH_STEP4 +
        "?CANCEL",
      confirmUrl:
        window.location.protocol +
        "//" +
        hostname +
        process.env.VUE_APP_BASE_URL +
        process.env.VUE_APP_RESERVATION_PATH +
        process.env.VUE_APP_RESERVATION_PATH_STEP4 +
        "?CONFIRM",
    };
  }
}
